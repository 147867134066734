import { Auth } from 'aws-amplify'
import Vue from 'vue'
import confirm from '../confirm/confirm.vue'
import VeeValidate from 'vee-validate'

Vue.use(VeeValidate)

Vue.component('confirm', confirm)

export default {
  name: 'Profile',
  data () {
    return {
      loading: true,
      userAttributes: {},
      user: null,
      userProfileImg: null,
      saving: false
    }
  },
  computed: {
    userInformation () {
      const user = this.$store.getters['session/userInformation']
      this.loading = false
      return user
    }
  },
  async created () {
    await this.getUser()
  },
  async mounted () {
    await this.getUser()
    this.loading = false
  },
  methods: {
    async getUser () {
      await this.$store.dispatch('session/retrieveUserInformation')
    },
    clearProfile () {
      this.userAttributes.profile = null
      this.userProfileImg = null
    },
    async saveProfile () {
      this.saving = true
      try {
        await this.$store.dispatch('session/saveUser', this.userInformation)
        this.$snotify.info('Successfully updated profile', {
          timeout: 3000,
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true
        })
      } catch (e) {
        this.$snotify.error(e.message, {
          timeout: 3000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true
        })
      } finally {
        this.saving = false
      }
    },
    cancel () {
      this.$router.go(-1)
    },
    logout: function () {
      const app = this
      Auth.signOut()
        .then(function () {
          app.$router.push({ name: 'login' })
        })
        .catch(err => console.log(err))
    },
    confirmLogout: function () {
      const scope = this
      this.$refs.confirm.open('Logout', this.$t('message.logoutMessage'), { color: 'primary', confirm: this.$t('button.confirm'), cancel: this.$t('button.cancel') }).then((confirm) => {
        if (confirm) {
          scope.logout()
        }
      })
    }
  }
}
